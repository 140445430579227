* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@font-face {
  font-family: futura-pt;
  src: url('../config/fonts/FuturaPTBook.otf');
}

body {
  border-top: solid 3px #ff321e;
  letter-spacing: 1px;
  font-family: futura-pt, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
}
