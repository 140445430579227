.wr-input-qty {
  display: flex;
  place-items: center;
  padding: 0 10px;
  height: 40px;
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 40px;
    height: 40px;
    border-radius: 50px;
    background-color: #fff;
    border: 1px solid #d4d4d4;
    i {
      color: #848484;
      width: 12px;
      height: 12px;
    }
  }
  .input-qty {
    margin: 0 5px;
    padding: 5px;
    text-align: center;
    max-width: 60px;
    height: 100%;
    box-shadow: none;
    outline: none;
    border: 1px solid #b8b8b8;
    background-color: initial;
    &:focus {
      outline: none;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}
