.tableStockLevel {
  text-align: center;
  th {
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    font-size: 16px;
    font-weight: 500;
  }
  tr {
    + tr {
      td {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
      }
    }
  }
  th,
  td {
    width: 50px;
    vertical-align: middle;
    padding-top: 17px;
    padding-bottom: 13px;
    padding-right: 5px;
    padding-left: 5px;
    &.clColorOption {
      text-align: left;
      width: 50px;
      padding-left: 5px;
    }
  }
  .lblColor {
    width: 22px;
    height: 22px;
    border: solid 1px #323232;
    background: #fff;
    display: inline-block;
  }
  .itemStatus {
    width: 18px;
    height: 18px;
    border-radius: 100px;
    border: solid 1px #fff;
    background: #fff;
    display: inline-block;
    &.itemStatus-2 {
      background: #2cd83d;
      border-color: #3b5e20;
    }
    &.itemStatus-1 {
      background: #fdf538;
      border-color: #5e3e20;
    }
    &.itemStatus-0 {
      background: #ff7272;
      border-color: #5e2020;
    }
  }
}

.tableStockCheck {
  display: table;
  width: 100%;
  td {
    height: 60px;
  }
  .tableColor {
    float: left;
    width: 50px;
  }
  .tableSize {
    margin-left: 50px;
  }
}
