$color-mark-default: #000;
$color-mark-hover: #111;
$color-mark-disable: #ccc;
$color-background: #fff;

.radio {
  $root: &;
  font-size: 22px;
  max-width: inherit;
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  user-select: none;

  display: flex;
  align-items: center;

  margin: 0;

  &:focus {
    outline: none;
  }

  &__input {
    /* Hide the browser's default radio button */
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    pointer-events: none;
  }

  &__mark {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background-color: $color-background;
    border: 1px solid black;
    transition: background 0.2s ease-in-out;
    margin: 0 auto;

    /* On mouse-over, add a grey background color */
    #{$root}:hover #{$root}__input ~ & {
      background-color: $color-background;
    }

    /* When the radio button is checked, add a  background */
    #{$root} #{$root}__input:checked ~ & {
      background-color: $color-background;
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    &:after {
      content: '';
      position: absolute;
      display: none;

      /* Style the indicator (dot/circle) */
      top: 2px;
      left: 2px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #000;
      vertical-align: text-top;
    }

    /* Show the indicator (dot/circle) when checked */
    #{$root} #{$root}__input:checked ~ &:after {
      display: block;
    }
  }

  &__title {
    display: inline-block;
    vertical-align: top;
    margin-left: -15px;
    height: 21px;
    font-family: 'FuturaPTBook';
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #191b27;
  }
}
