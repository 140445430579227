.productCarePage {
  padding-bottom: 100px;
  .titlePage {
    font-size: 32px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: normal;
    padding-bottom: 31px;
    padding-top: 30px;
  }
  .listItem {
    .titleList {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 20px;
      margin-top: 35px;
    }
  }
  .itemProductCare {
    padding-right: 40px;
    margin-bottom: 30px;

    .btnShow {
      font-size: 20px;
      font-weight: 500;
      line-height: 1.7;
      letter-spacing: 0.77px;
      cursor: pointer;
      margin-bottom: 4px;
      .icnIconWrap {
        width: 30px;
        height: 34px;
        float: left;
        display: flex;
        align-items: center;
      }
      .icnIcon {
        transition: all ease 0.5s;
      }
      &.isOpened {
        .icnIcon {
          transform: rotate(90deg);
        }
      }
    }
    .itemCollapse {
      .titleContent {
        font-weight: 500;
        margin-bottom: 10px;
      }
      .item {
        margin-top: 15px;
      }
      .contentItemDesc {
        p {
          margin-bottom: 5px;
        }
      }
      b,
      strong {
        font-weight: 500;
      }
      a {
        text-decoration: underline;
      }
    }
  }
}
