$colorRed: #ff321f;
$colorBlue: #0779ff;
$heightHeader: 81px;

$srcBgImage: '../assets';

$widthContainer: 1330px;

$mainColor: #000;
$mainFont: 'FuturaPTBook';
$secondaryFont: 'Open Sans';
$mainFontSize: 16px;
