@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap&subset=cyrillic,cyrillic-ext,greek,greek-ext,latin-ext,vietnamese');
@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:200,300,400,600,700,800,900&display=swap');

@font-face {
  font-family: FuturaPTBook;
  src: url('../config/fonts/FuturaPTLight.otf');
  font-weight: 300;
}
@font-face {
  font-family: FuturaPTBook;
  src: url('../config/fonts/FuturaPTBook.otf');
  font-weight: normal;
}
@font-face {
  font-family: FuturaPTBook;
  src: url('../config/fonts/FuturaPTMedium.otf');
  font-weight: 500;
}

::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-button {
  display: none;
}
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
}
::-webkit-scrollbar-track-piece {
  background: rgba(0, 0, 0, 0.2);
}
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*,
:before,
:after {
  box-sizing: border-box;
}

a,
button,
input,
textarea {
  outline: none !important;
}

img {
  max-width: 100%;
}

body {
  border-top: unset;
  font-family: FuturaPTBook, sans-serif !important;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  letter-spacing: 0.5px;
  color: #000;
}
.table {
  color: #000;
}
ul {
  list-style: none;
  margin-bottom: 0;
}
.txt_datepicker {
  &[readonly]:focus {
    border: 1px solid #4c63e0 !important;
  }
}
input[type='text'],
input[type='email'],
input[type='tel'],
input[type='number'],
input[type='password'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -moz-appearance: textfield;
}

.cursor-pointer {
  cursor: pointer;
}
.container {
  max-width: 1330px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.fs-10 {
  font-size: 10px;
}

.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

.fs-22 {
  font-size: 22px;
}

.fs-24 {
  font-size: 24px;
}

.fs-28 {
  font-size: 28px;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.w-30 {
  width: 30%;
}

.w-70 {
  width: 70%;
}

.pb-10px {
  padding-bottom: 10px;
}

.p-15px {
  padding: 15px;
}

.pt-10px {
  padding-top: 10px;
}

.pb-10px {
  padding-bottom: 10px;
}

.p-10px {
  padding: 10px;
}

.m-15px {
  margin: 15px;
}

.mb-10px {
  margin-bottom: 10px;
}

.mb-15px {
  margin-bottom: 15px;
}

.mt-10px {
  margin-top: 10px;
}

.mt-15px {
  margin-top: 15px;
}

.m-10px {
  margin: 10px;
}

.w-60 {
  width: 60%;
}
.btnWithArrow {
  height: 43px;
  display: inline-block;
  position: relative;
  color: #fff;
  background: #000;
  border: none;
  min-width: 190px;
  padding-right: 45px;
  padding-left: 5px;
  text-align: center;
  line-height: 43px;
  &:hover {
    color: #fff;
  }
  &:disabled {
    background: rgba(0, 0, 0, 0.3);
  }

  .icnArrow {
    position: absolute;
    height: 43px;
    width: 43px;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid rgba(255, 255, 255, 0.8);
  }
}
.text-decoration-through {
  text-decoration: line-through;
}

.colorBlue {
  color: #0779ff;
}

.d-flex-column {
  display: flex;
  flex-direction: column;
}

.d-flex-row {
  display: flex;
  flex-direction: row;
}

.show-less {
  color: rgb(5, 120, 255);
  margin: 10px 10px 5px 0;
  i {
    font-size: 1.25em;
  }
}

.gr-radio-label {
  display: flex;
  place-items: center;
}

.one-row-two-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

a {
  color: #000;
  text-decoration: none;
  &:hover {
    color: #000;
    text-decoration: none;
  }
}

.button,
.cart-table .product-cart-actions .button,
#co-shipping-method-form .buttons-set .button,
.footer .button {
  background: #ff321e;
  display: inline-block;
  padding: 20px 17px;
  border: 0;
  color: #ffffff;
  font-size: 20px;
  font-family: FuturaPTBook, sans-serif;
  line-height: 19px;
  letter-spacing: 2px;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
}

input[type='date'],
input[type='datetime-local'],
input[type='month'],
input[type='time'] {
  -webkit-appearance: textfield;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    display: none;
  }
}

input[type='email'],
input[type='search'],
input[type='number'],
input[type='password'],
input[type='tel'],
input[type='text'] {
  width: 365px;
  min-width: 70px;
  border: 1px solid #b8b8b8;
  max-width: 100%;
  height: 50px;
  padding: 0 8px;
}

.page-title {
  h1 {
    font-size: 34px;
    font-weight: 500;
    color: #636363;
    border-bottom: 1px solid #ededed;
    padding-bottom: 3px;
    margin-bottom: 15px;
    text-transform: uppercase;
  }
}

.accordion {
  .show-list.collapse:not(.show) {
    display: block;
  }
}

.react-datepicker__triangle {
  left: 50% !important;
  margin-left: 0 !important;
}

.react-datepicker-wrapper {
  width: 130px;
}

.MuiSkeleton-root {
  background-color: #ececec !important;
}
